.TrainingMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0;

}

.blocCarrousel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.blocInfotraining {
  display: flex;
  width: 85%;
  height: auto;
}

.blocDescriptionTraining {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  height: auto;
  width: 87%;
  padding-bottom: 15px;
  padding-top: 15px;
}

.subtitleProject {
  font-family: 'Lastica', sans-serif;
  letter-spacing: 2px;
  word-spacing: 2px;
  color: #975ab6;
}

.descriptionTraining,
.difficultyTraining {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 142.6%;
  grid-gap: 20px;
  padding: 0 20px;
}



@media (max-width: 768px) {
  .TrainingMain {
    padding-top: 0;
  }
  .blocCarrousel {
    padding-top: 30px;
  }

  .blocInfotraining {
    flex-direction: column;
    width: 90%;
  }

  .blocDescriptionTraining {
    flex-direction: column;
    grid-gap: 20px;
    width: 90%;
    padding-top: 0;
    padding: 0;

  }

  .descriptionTraining,
  .difficultyTraining {
    padding: 0;
  }

}