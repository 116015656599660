.containerNotfound {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    grid-gap: 30px;
    padding-top: 60px;
  }
  
  .h2Notfound {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 250px;
  }
  
  .pNotfound {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
  }
  
  .spanNotfound {
    color: #ff6060;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-decoration: underline;
    cursor: pointer;
    padding-bottom: 100px;
  }
  
  @media (max-width: 768px) {
    .h2Notfound {
      font-size: 96px;
      padding-top: 198px;
    }
  
    .pNotfound {
      font-size: 18px;
      text-align: center;
    }
  
    .spanNotfound {
      font-size: 14px;
      padding-top: 150px;
      padding-bottom: 235px;
    }
  }
  