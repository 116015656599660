.skillsMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
  width: 100%;
  height: auto;
}

.hardsSkills {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 40px 0;
}

.softSkills {
    padding: 10px 10px;
    width: 70%;
}

.description {
  display: flex;
  flex-direction: column;
  padding: 30px;
  height: auto;
}

@media (max-width: 1130px) {
  .softSkills {
    width: 75%;
  }
}

@media (max-width: 992px) {
  .softSkills {
    width: 90%;
  }
}

