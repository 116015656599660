header {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media (max-width: 768px) {
  header {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20px;
    padding-bottom: 0px;
  }
}
