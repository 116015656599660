.card {
    display: flex;
    position: relative;
    border-radius: 10px;
    height: 340px;
    width: 85%;
    margin: auto;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(2, 0, 36);
    background: linear-gradient(
      0deg,
      rgba(2, 0, 36, 0.7735042735042735) 0%,
      rgba(0, 0, 0, 0) 48%
    );
    z-index: 1;
    border-radius: 10px;
  }
  
  .card p {
    width: 50%;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    align-self: end;
    padding-left: 20px;
    padding-bottom: 20px;
    z-index: 3;
  }
  
  @media (max-width: 768px) {
    .card {
      width: 100%;
      height: 280px;
    }
  
    .card p {
      font-size: 14px;
    }
  }
  