.aboutMain {
  height: auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding-bottom: 60px;
}

.sectionTitle {
  display: flex;
  font-family: "Lastica", sans-serif;
  color: #975ab6;
  border-bottom: 1px solid #975ab6;
  width: 100%;
  justify-self: flex-start;
  letter-spacing: 2px;
  word-spacing: 2px;
}

.identityBloc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 0 20px 0;
  grid-gap: 20px;
  width: 40%;
}

.supplementaries {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 30px;
  height: auto;
  width: 90%;
  padding: 40px 0 30px 30px;
}

.infoBloc {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px 0 20px 0;
  grid-gap: 30px;
  width: 50%;
}

@media (max-width: 992px) {
  .aboutMain {
    flex-direction: column;
    align-items: center;
  }
  .supplementaries {
    flex-direction: row;
    height: auto;
    padding: 5px 0;
  }
  .identityBloc {
    width: 90%;
  }
  .infoBloc {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
    .supplementaries {
        flex-direction: column;
        height: auto;
    }
}