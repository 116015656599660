.gallery {
  width: 100%;
  height: auto;
  border-radius: 15px;
  margin-top: 40px;
  margin-bottom: 40px;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  padding: 2%;
}

@media (max-width: 1100px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 993px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
    overflow: hidden;
    height: auto;
    background: none;
    padding-top: 20px;
  }
}

@media (max-width: 768px) {
  .gallery {
    height: auto;
    grid-template-columns: repeat(1, 1fr);
    overflow: hidden;
    height: auto;
    margin-top: 10px;
    margin-bottom: 20px;
    grid-gap: 15px;
    border: none;
  }
}
