.tagContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 37px;
  padding-right: 37px;
  width: auto;
  height: 25px;
  background: #975ab6;
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 142.6%;
  color: #ffffff;
}

@media (max-width: 768px) {
  .tagContainer {
    height: 18px;
    border-radius: 5px;
    font-size: 10px;
    padding-left: 27px;
    padding-right: 27px;
  }
}