.trainingsMain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
}

.trainingsSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: auto;
    padding-bottom: 40px;
    padding-top: 40px;
}

.titleSection {
    color: #975ab6;
    font-family: 'Lastica', sans-serif;
    border-bottom: 1px #975ab6 solid;
    align-self: flex-start;
    width: 100%;
    letter-spacing: 2px;
    word-spacing: 10px;
}
