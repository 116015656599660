.selftrainingMain {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 30px 60px 30px;
}

.articleBloc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.sectionTitle {
  display: flex;
  font-family: "Lastica", sans-serif;
  color: #975ab6;
  border-bottom: 1px solid #975ab6;
  width: 90%;
  justify-self: flex-start;
  letter-spacing: 2px;
  word-spacing: 2px;
}

.articleLayout {
    display: flex;
    justify-content: space-around;
    width: 90%;
}

.article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 30px;
    gap: 15px;
}

.articleTitle {
    font-family: 'Lastica', sans-serif;
    color: #975ab6;
    letter-spacing: 2px;
    word-spacing: 2px;
}

.articleContent {
    font-family: 'Roboto', sans-serif;

}

@media screen and (max-width: 992px) {
    .articleLayout {
        flex-direction: column;
        gap: 30px;
    }
}

@media screen and (max-width: 768px) {
    .selftrainingMain {
        padding: 30px 10px 60px 10px;
    }

    .articleLayout {
        flex-direction: column;
        gap: 30px;
    }
    .article {
        padding: 0;
    }
}