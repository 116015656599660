.carrousel {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-size: cover;
  background-position: top;
  height: 500px;
  width: 85%;
  border-radius: 15px;
  padding: 10px;
}

.homeCarrousel {
  height: 300px;
  width: 70%;
}

.arrowBall {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  border-radius: 10px;
  opacity: 0.7;
}

.arrowLeft, .arrowRight {
  cursor: pointer;
  padding: 0;
  height: 50px;
  padding-top: 8px;
}

@media (max-width: 768px) {
  .carrousel {
    width: 90%;
    height: 255px;
    border-radius: 10px;
  }

  .homeCarrousel {
    height: 300px;
    width: 90%;
  }

  .arrowLeft,
  .arrowRight {
    height: 25px;
    padding-top: 4px;
  }
  .arrowRight {
    height: 25px;
  }

  .account {
    display: none;
  }
}