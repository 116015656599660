.motiveBloc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-family: 'Roboto', sans-serif;
    grid-gap: 10px;
    padding-bottom: 20px;
}


